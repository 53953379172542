import styled from "@emotion/styled";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
export const StyledResponsiveComponent = styled.div`
  .desktop-wrapper {
    display: block;
  }

  .mobile-wrapper {
    display: none;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .desktop-wrapper {
      display: none;
    }

    .mobile-wrapper {
      display: block;
    }
  }
`;
