const SLICK_OPACITY_ON_HOVER = 1;
const SLICK_OPACITY_NOT_ACTIVE = 0.25;
const SLICK_OPACITY_DEFAULT = 0.75;
const SLICK_DOT_SIZE = 0.338;
const SLICK_NEXT_CHARACTER = "\u2192";
const SLICK_PREV_CHARACTER = "\u2190";
const SLICK_DOT_CHARACTER = "\u2022";
export const slickThemeStyle = `
  .slick-list {
    .slick-loading & {
      background: #fff url("./ajax-loader.gif") center center no-repeat;
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;

    &:hover,
    &:focus {
      outline: none;
      background: transparent;
      color: transparent;

      &:before {
        opacity: ${SLICK_OPACITY_ON_HOVER};
      }
    }

    &.slick-disabled:before {
      opacity: ${SLICK_OPACITY_NOT_ACTIVE};
    }

    &:before {
      font-size: 1.125rem;
      line-height: 1;
      color: white;
      opacity: ${SLICK_OPACITY_DEFAULT};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .slick-prev {
    left: -25px;

    [dir="rtl"] & {
      left: auto;
      right: -25px;
    }

    &:before {
      content: ${SLICK_NEXT_CHARACTER};

      [dir="rtl"] & {
        content: ${SLICK_PREV_CHARACTER};
      }
    }
  }

  .slick-next {
    right: -25px;

    [dir="rtl"] & {
      left: -25px;
      right: auto;
    }

    &:before {
      content: ${SLICK_PREV_CHARACTER};

      [dir="rtl"] & {
        content: ${SLICK_NEXT_CHARACTER};
      }
    }
  }

  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  .slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        border: 0;
        background: transparent;
        display: block;
        height: 20px;
        width: 20px;
        outline: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        padding: 5px;
        cursor: pointer;

        &:hover,
        &:focus {
          outline: none;

          &:before {
            opacity: ${SLICK_OPACITY_ON_HOVER};
          }
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: ${SLICK_DOT_CHARACTER};
          width: 20px;
          height: 20px;
          font-size: ${SLICK_DOT_SIZE};
          line-height: 20px;
          text-align: center;
          color: black;
          opacity: ${SLICK_OPACITY_NOT_ACTIVE};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }

      &.slick-active button:before {
        color: black;
        opacity: ${SLICK_OPACITY_DEFAULT};
      }
    }
  }
`;
